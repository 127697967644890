<template>
  <div>
    <div class="loader-overlay" v-if="showLoader">
      <b-spinner label="Loading..." style="width: 6rem; height: 6rem;" variant="primary" />
    </div>
    <div v-else>
      <div v-if="dataFound">
        <div class="container">
          <b-card>
            <template>
              <div class="row align-items-center">
                <div class="col-xl-2 col-sm-2 col-4">
                  <img class="app-icon w-100" :src="icon" alt="" />
                </div>
                <div class="col-8 col-sm-5">
                  <h2 class="d-flex justify-content-between">
                    <span class="app-title">{{ title }}</span>
                  </h2>
                  <p class="app-summary" v-html="summary"></p>
                  <span class=" d-flex align-items-center mb-1">
                      <span class="font-weight-bold">bundle ID</span>
                      <span class="app-id mx-1" @click="copyToClipboard(appId)" v-b-tooltip="{
                            title:
                              '<h6><strong>' +
                              appId +
                              '</strong></h6> (Click to copy)',
                            html: true,
                          }" variant="outline-success">
                        <feather-icon icon="ClipboardIcon" class="text-primary" size="20" />
                      </span>
                    </span>
                  <a class="app-account appValue text-primary" @click="searchDev" style="cursor: pointer">
                    {{ account }}
                  </a>
                </div>
                <div class="col-12 col-sm-5 mt-2 mt-sm-auto d-inline-flex justify-content-end">
                  <b-button class="mr-1 mr-md-2 " @click="downloadZip"
                    style="font-weight: 500 !important; font-size:12px !important;padding-inline:6px !important"
                    v-if="!screensLoadingDownload" variant="primary">
                    <feather-icon icon="DownloadIcon" size="15" />
                    Download Assets
                  </b-button>
                  <b-button variant="success" class="mr-1 mr-md-2 pr-1" v-else>
                    <b-spinner small></b-spinner>
                  </b-button>
                  <b-button class="m-0"
                    v-if="nichesId != 1"
                    style="font-weight: 500 !important; font-size:12px !important;padding-inline:6px !important"
                    @click="downloadApk" variant="primary">
                    <feather-icon icon="DownloadIcon" size="15" />
                    Download APK
                  </b-button>
                </div>
              </div>
            </template>

          </b-card>
          <div class="app-info-wrapper">
            <b-card class="app-info-item" id="infoCards">
              <p class="appKey">Category</p>
              <p class="appValue">{{ genre }}</p>
            </b-card>
            <b-card class="app-info-item" id="infoCards">
              <p class="appKey">Price</p>
              <p class="appValue">{{ priceText }}</p>
            </b-card>
            <b-card class="app-info-item" id="infoCards">
              <p class="appKey">Downloads</p>
              <p class="appValue">{{ installs }}</p>
            </b-card>
            <b-card class="app-info-item" id="infoCards">
              <p class="appKey">Version</p>
              <p class="appValue">{{ androidVersionText }}</p>
            </b-card>
            <b-card class="app-info-item" id="infoCards">
              <p class="appKey">Score</p>
              <p class="appValue">{{ scoreText }}</p>
            </b-card>
            <!-- <b-card class="app-info-item" id="infoCards">
            <p class="appKey"></p>
            <p class="appValue">{{ appId }}</p>
          </b-card> -->
            <b-card class="app-info-item" id="infoCards">
              <p class="appKey">Open in</p>
              <p class="appValue">
                <a :href="playstoreUrl" target="_blank">
                  <feather-icon icon="LinkIcon" size="20" /> Play Store
                </a>
              </p>
            </b-card>
          </div>
        </div>

        <b-container>

          <b-row class="justify-content-center">
            <b-col cols="12" md="8" sm="7">
              <b-card id="appInfoMain">
                <template>
                  <h3 class="mb-3">Screenshoots </h3>
                  <div class="image-container">
                    <div class="image-wrapper">
                      <div v-for="(screenshot, index) in screenshots" :key="index" class="image-item">
                        <img :src="screenshot" alt="Screenshot" />
                      </div>
                    </div>
                  </div>
                </template>
                <hr class="my-3" />
                <div>
                  <h3 class="mb-3">Description </h3>
                  <p v-html="descriptionHTML" :class="isArabicText(description) ? 'rtl' : 'ltr'"></p>
                </div>
              </b-card>
              <b-card no-body>
                <h3 class="m-1">Keywords</h3>
                <div
                  style="display:flex !important;justify-content:center !important;align-items: center !important;padding-top:1rem">
                  <ul class="cloud" role="navigation" aria-label="Webdev tag cloud">
                    <li v-for="(keyword, index) in keywords" :key="index">
                      <a :data-weight="keyword.count" :style="`color:${CloudColor}`"
                        @click="copyToClipboard(keyword.word)">{{
                          keyword.word }}</a>
                    </li>
                  </ul>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12" md="4" sm="5">
              <b-card>
                <div class="asideWrapper">
                  <h3>About</h3>
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <span class="font-weight-bold">Version</span>
                      <span class="text-secondary app-version">{{ version }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <span class="font-weight-bold">Last Update</span>
                      <span class="text-secondary app-released-date">
                        {{ formatDate(updated) }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <span class="font-weight-bold">Released</span>
                      <span class="text-secondary app-released-date">{{
                        releasedDate
                      }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center" v-if="refreshed">
                      <span class="font-weight-bold">Refreshed</span>
                      <span class="text-secondary app-refreshed-at">{{
                        moment(refreshed, "YYYY-MM-DD").format("MMM DD, YYYY")
                      }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <span class="font-weight-bold">Ratings</span>
                      <span class="text-secondary app-ratings">{{ ratings }}</span>
                    </li>
                    <li class="appidLi list-group-item d-flex justify-content-between align-items-center">
                      <span class="font-weight-bold">Developer's Email</span>
                      <span class="app-id" @click="copyToClipboard(developerEmail)" v-b-tooltip="{
                        title:
                          '<h6><strong>' +
                          developerEmail +
                          '</strong></h6> (Click to copy)',
                        html: true,
                      }" variant="outline-success">
                        <feather-icon icon="EyeIcon" class="text-primary" size="20" />
                      </span>
                    </li>
                    <li class="appidLi list-group-item d-flex justify-content-between align-items-center">
                      <span class="font-weight-bold">Developer's Website</span>
                      <span class="app-id" @click="copyToClipboard(developerWebsite)" v-b-tooltip="{
                        title:
                          '<h6><strong>' +
                          developerWebsite +
                          '</strong></h6> (Click to copy)',
                        html: true,
                      }" variant="outline-success">
                        <feather-icon icon="EyeIcon" class="text-primary" size="20" />
                      </span>
                    </li>
                    <li class="appidLi list-group-item d-flex justify-content-between align-items-center">
                      <span class="font-weight-bold">Content Rating</span>
                      <span class="app-content-rating text-secondary">{{
                        contentRating
                      }}</span>
                    </li>
                    <li class="appidLi list-group-item d-flex justify-content-between align-items-center">
                      <span class="font-weight-bold">bundle ID</span>
                      <span class="app-id" @click="copyToClipboard(appId)" v-b-tooltip="{
                            title:
                              '<h6><strong>' +
                              appId +
                              '</strong></h6> (Click to copy)',
                            html: true,
                          }" variant="outline-success">
                        <feather-icon icon="ClipboardIcon" class="text-primary" size="20" />
                      </span>
                    </li>
                  </ul>
                </div>
              </b-card>
              <b-card v-if="niches">
                <div v-if="comeReviews">
                  <h3>Ratings and reviews </h3>
                  <div id="reviewsCard">
                    <div class="table" v-for="review in reviewsListData" :key="review.userImage">

                      <div class="table-row">
                        <div>
                          <img :src="review.userImage" alt="userProfile" style="border:none;border-radius: 50%;"
                            width="40" height="40" />
                          <span class="text-dark ml-1 font-weight-bold">{{ review.userName }}</span>
                        </div>
                        <div class="mt-1">
                          <p class="font-weight-bold">
                            <template>
                              <feather-icon v-for="i in 5" icon="StarIcon" :key="i"
                                :class="{ 'text-warning feather feather-star stroke-current fill-current h-6 w-6': i <= review.score, 'text-secondary': i > review.score }"></feather-icon>
                            </template>
                            {{ review.date.length > 10 ? review.date.substring(0, 16).replace('T', ' ') : review.date }}
                          </p>
                        </div>
                        <div>
                          <p class="font-weight-bolder text-dark">
                            {{ review.text }}
                          </p>
                          <hr class="w-75 mx-auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center" style="height: 5rem;" v-else>
                  <b-spinner label="Loading..." variant="primary" />
                </div>
              </b-card>
              <b-card v-if="niches">
                <div v-if="comeReviews">
                  <h3 class="mb-2">Similar Apps / Games</h3>
                  <div>
                    <div class="container-fluid">
                      <div class="row ">
                        <div class="col-6 col-md-6 col-lg-4" v-for="app in similarListData" :key="app.appId">
                          <router-link :to="{
                            name: 'market-manager-accounts-search',
                            query: {
                              search: isNaN(app.appId)
                                ? app.developer.devId
                                : app.appId,
                            },
                          }" target="_blank">
                            <img :src="app.icon" alt="icon" class="similarAppIcon" />
                            <p style="font-size: 10px;font-weight: 800;" class="text-dark" v-b-tooltip.hover.bottom="{
                              title: '<strong>' + app.title + '</strong> ',
                              html: true,

                            }">
                              {{ app.title.length > 10 ? app.title.substring(0, 10) + '...' : app.title }}
                            </p>
                          </router-link>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center" style="height: 5rem;" v-else>
                  <b-spinner label="Loading..." variant="primary" />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="row mt-5 justify-content-center" v-else>
        <div class="col-md-6 col-sm-12">
          <div class="text-center">
            <feather-icon icon="AlertCircleIcon" size="80" />
            <h3 class="my-2" style="font-size: 3rem !important;">No data available</h3>
            <p class="h4">This Application Was deleted please Go back and Select
              Another
              Application.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import VueApexCharts from "vue-apexcharts";

import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormInput,
  BLink,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BFormSelect,
  BContainer,
  BSpinner,
  BCarousel,
  BCarouselSlide,
  BTab, BTabs,
} from "bootstrap-vue";
import store from "@/store";
import axios from "@axios";
import router from "@/router";
import { deleteAccessTokenCookie } from "@/helper/helpers.js";
import { connectApi } from "@/helper/connectApi";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Chart from "chart.js";
import moment from "moment";

export default {
  components: {
    // BCardCode,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BCardText,
    BContainer,
    BTab, BTabs,
    apexchart: VueApexCharts,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    BLink,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCarousel,
    BCarouselSlide,
    BSpinner,

  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      country:"",
      lang:"",
      moment,
      appId: null,
      title: null,
      icon: null,
      score: null,
      releasedDate: null,
      version: null,
      summary: null,
      description: null,
      screenshots: null,
      installs: null,
      ratings: null,
      account: null,
      genre: null,
      refreshed: null,
      developer: {},
      priceText: null,
      installs: null,
      scoreText: null,
      updated: null,
      genre: null,
      androidVersionText: null,
      screenshoots: [],
      appId: null,
      playstoreUrl: null,
      idapp: null,
      developerEmail: null,
      developerWebsite: null,
      contentRating: null,
      showMore: false,
      reviewsListData: [],
      similarListData: [],
      permessionData: [],
      data: {},
      slide: 0,
      sliding: null,
      // validation rules
      required,
      regex,
      url,
      charts: false,
      installsFilter: "0",
      reviewsFilter: "0",
      installsData: [],
      reviewsData: [],
      installsDates: [],
      reviewsDates: [],
      installsLastDays: 7,
      reviewsLastDays: 7,
      keywords: null,
      screensLoadingDownload: false,
      descriptionHTML: null,
      dataFound: true,
      showLoader: true,
      devId: null,
      routes: [
        '/get-removed-apps-info',
        '/get-top-guides-apps-info',
        '/get-top-coloring-apps-info',
        '/get-top-wallpaper-apps-info',
        '/get-top-fakecall-apps-info',
        '/get-top-vpn-apps-info',
        '/get-top-10k-apps-info',
      ],
      niches: true,
      nichesId: null,
      comeReviews:true

    };
  },
  watch: {
    installsLastDays: {
      immediate: true, // Trigger the watcher immediately on component mount
      handler(newVal, oldVal) {
        // this.getInstallsData();
      },
    },
    reviewsLastDays: {
      immediate: true, // Trigger the watcher immediately on component mount
      handler(newVal, oldVal) {
        // this.getReviewsData();
      },
    },
  },
  computed: {
    CloudColor() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return "white"
      }
      return "black"
    },
    installsChartOptions() {
      return {
        chart: {
          id: "installs-chart",
        },
        colors: ["#ccc"],
        xaxis: {
          categories: this.installsDates,
        },

      };
    },
    reviewsChartOptions() {
      return {
        chart: {
          id: "reviews-chart",
        },
        colors: ["#ccc"],

        xaxis: {
          categories: this.reviewsDates,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value + 20;
            },
          },
        },
      };
    },
    installsChartSeries() {
      return [
        {
          name: "Installs",
          // data: this.installsData,
          // data: [300,350,540,340,593],
        },
      ];
    },
    reviewsChartSeries() {
      return [
        {
          name: "Reviews",
        },
      ];
    },
  },
  mounted() {
    this.country = this.$route.params.country;
    this.lang = this.$route.params.lang;
    setTimeout(() => {
      this.showLoader = false;
    }, 1000);
    if (this.$route.params.from === "db") {
      this.getSelectedApp();
    } else if (this.$route.params.from === "api") {
      this.getSelectedAppFromApi();
    } else if (this.$route.params.from === "niches") {
      this.niches = false;
      this.nichesId = this.$route.params.route;
      this.getSelectedAppNiches(this.$route.params.route);
    }
  },
  methods: {
    isArabicText(content) {
      const arabicRegex = /[\u0600-\u06FF]/;
      return arabicRegex.test(content);
    },
    copyToClipboard(dataItem) {
      const tempInput = document.createElement("input");
      tempInput.value = dataItem;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.showToast(
        "success",
        "SaveIcon",
        "Done",
        dataItem + " Copied to Clipboard"
      );
    },
    async searchDev() {
      const query = {
        search: this.devId
      };
      let route = router.resolve({
        name: "market-manager-accounts-search",
        query: query,
        target: "_blank",
      });
      window.open(route.href);
    },
    setInstallsLastDays(event) {
      const selectedValue = event.target.value;
      this.installsLastDays = selectedValue;
    },
    setReviewsLastDays(event) {
      const selectedValue = event.target.value;
      this.reviewsLastDays = selectedValue;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    truncateText(element) {
      const text = element.textContent;
      element.textContent = text.slice(0, 6);
    },
    toggleDescription() {
      this.showMore = !this.showMore;
    },

    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async getAppDetailData() {
      // axios
      //   .get(
      //     `https://scx.clicklab.app/api/apps/${this.app_ID}/?fullDetail=true&token=${this.token}`
      //   )
      const response = connectApi(`https://scx.clicklab.app/api/apps/${this.appId}/?fullDetail=true&country=${this.country}&lang=${this.lang}`);
      response
        .then((response) => {
          const { status } = response.data;
          if (status === "Unauthorized") {
            deleteAccessTokenCookie();
            router.push({ name: "auth-login" });
            return;
          }
          const data = response.data;
          return data;
        })
        .then((data) => {
          this.title = data.title;
          this.icon = data.icon;
          this.releasedDate = data.released;
          this.summary = data.summary;
          this.version = data.version;
          this.installs = data.maxInstalls;
          this.ratings = data.ratings;
          this.account = data.developer.devId;
          this.screenshots = data.screenshots;
          this.priceText = data.priceText;
          this.installs = data.installs;
          this.genre = data.genre;
          this.scoreText = data.scoreText;
          this.description = data.description;
          this.androidVersionText = data.androidVersionText;
          this.playstoreUrl = data.playstoreUrl;
          this.appId = data.appId;
          this.updated = data.updated;
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "can't fetch the data!"
          );
        });
    },

    getReviewsData() {
      axios
        .post(`/get-app-reviews`, {
          appId: this.idapp,
          period: this.reviewsLastDays,
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.status == "Unauthorized") {
          } else {
            var formatedData = [];
            data.created_at.forEach((element) => {
              let dateF = formatDate(element);
              formatedData.push(dateF);
            });
            this.reviewsDates = formatedData;
            this.reviewsData = data.reviews;
          }
        })
        .catch((exception) => console.error(exception));
    },

    getInstallsData() {
      axios
        .post(`/get-app-installs`, {
          appId: this.idapp,
          period: this.installsLastDays,
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.status == "Unauthorized") {
          } else {
            let formatedData = [];
            data.created_at.forEach((element) => {
              let dateF = formatDate(element);
              formatedData.push(dateF);
            });
            this.installsDates = formatedData;
            this.installsData = data.installs;
            const xaxisElement = document.querySelectorAll(
              "text.apexcharts-xaxis-label"
            );
            xaxisElement.forEach((element) => {
              truncateText(element);
            });
          }
        })
        .catch((exception) => console.error(exception));
    },

    // drawInstallsChart(labels, data) {
    //   const ctx = this.$refs.installsChart;
    //   new Chart(ctx, {
    //     type: "line",
    //     data: {
    //       labels: labels,
    //       datasets: [
    //         {
    //           label: "# Installs",
    //           data: data,
    //           borderWidth: 2,
    //           borderColor: "#4bc0c0",
    //           backgroundColor: "#4bc0c030",
    //           hoverBackgroundColor: "#FFF",
    //           fill: "start",
    //         },
    //       ],
    //     },
    //     options: {
    //       scales: {
    //         yAxes: [
    //           {
    //             ticks: {
    //               beginAtZero: true,
    //               min: 0, // Start the y-axis at 0
    //               max: labels.length, // Set the maximum value to the total number of days
    //               stepSize: 1, // Set the step size to 1
    //             },
    //           },
    //         ],
    //         xAxes: [
    //           {
    //             ticks: {
    //               beginAtZero: true,
    //               min: 20, // Start the x-axis at 20 installs per day
    //               max: 100, // Set the maximum value to 100 installs per day
    //               stepSize: 20, // Set the step size to 20
    //             },
    //           },
    //         ],
    //       },
    //     },
    //   });
    // },

    // drawReviewsChart(labels, ratings, scores) {
    //   const ctx = this.$refs.ReviewsChart;
    //   new Chart(ctx, {
    //     type: "line",
    //     data: {
    //       labels: labels,
    //       datasets: [
    //         {
    //           label: "# Reviews",
    //           data: ratings,
    //           yAxisID: "ratings-y-axis",
    //           // showLine: true,
    //           borderWidth: 2,
    //           borderColor: "#4bc0c0",
    //           backgroundColor: "#4bc0c030",
    //           hoverBackgroundColor: "#FFF",
    //         },
    //         {
    //           label: "# Scores",
    //           data: scores,
    //           yAxisID: "scores-y-axis",
    //           borderWidth: 2,
    //           borderColor: "rgb(255, 193, 7)",
    //           backgroundColor: "rgba(255, 193, 7, .25)",
    //           hoverBackgroundColor: "#FFF",
    //         },
    //       ],
    //     },
    //     options: {
    //       scales: {
    //         yAxes: [
    //           {
    //             id: "ratings-y-axis",
    //             type: "linear",
    //             position: "left",
    //             // display: false,
    //             gridLines: {
    //               drawOnChartArea: false,
    //               // drawTicks: false,
    //             },
    //             ticks: {
    //               // display: false,
    //               beginAtZero: true,
    //             },
    //           },
    //           {
    //             id: "scores-y-axis",
    //             type: "linear",
    //             position: "right",
    //             // display: false,
    //             gridLines: {
    //               drawOnChartArea: false,
    //               // drawTicks: false,
    //             },
    //             ticks: {
    //               // display: false,
    //               beginAtZero: true,
    //               suggestedMin: 0,
    //               suggestedMax: 5,
    //             },
    //           },
    //         ],
    //         xAxes: [
    //           {
    //             // // display: false,
    //             // gridLines: {
    //             //   drawOnChartArea: false,
    //             //   // drawTicks: false,
    //             // },
    //           },
    //         ],
    //         // y: {
    //         //   beginAtZero: true,
    //         // },
    //       },
    //     },
    //   });
    // },
    async getSelectedAppNiches(route) {
      await axios
        .post(this.routes[route - 1], {
          app: this.$route.params.id,
        })
        .then(({ data }) => {
          const { app } = data;
          this.idapp = app.id;
          this.appId = app.appId;
          this.title = app.title;
          this.icon = app.icon;
          this.releasedDate = app.released;
          this.score = app.scoreText;
          this.summary = app.summary;
          this.version = app.version;
          this.ratings = app.ratings;
          this.account = JSON.parse(app.developer).devId;
          this.devId = isNaN(app.devId) ? JSON.parse(app.developer).devId : app.devId;
          this.genre = app.genre;
          this.screenshots = JSON.parse(app.screenshots);
          this.priceText = app.priceText;
          this.installs = app.Installs;
          this.genre = app.genre;
          this.scoreText = app.scoreText;
          this.description = app.description;
          this.androidVersionText = app.androidVersionText;
          this.playstoreUrl = app.playstoreUrl;
          this.appId = app.appId;
          this.updated = parseInt(app.updated);
          this.developerEmail = app.developerEmail;
          this.developerWebsite = app.developerWebsite;
          this.contentRating = app.contentRating;
          const topKeywords = this.extractKeywords(this.description + " " + this.title, 10);
          this.keywords = topKeywords;
          this.keywords.sort(() => Math.random() - 0.5);
          // this.getInstallsData();
          // this.getReviewsData();
          // this.getReviewsFromApi();
          this.getDiscHtml()
        })
        .catch(console.error);
    },
    async getSelectedApp() {
      await axios
        .post(`/get-app`, {
          app: this.$route.params.id,
        })
        .then(({ data }) => {
          const { app } = data;
          this.idapp = app.id;
          this.appId = app.appId;
          this.title = app.title;
          this.icon = app.icon;
          this.releasedDate = app.released;
          this.score = app.scoreText;
          this.summary = app.summary;
          this.version = app.version;
          this.ratings = app.ratings;
          this.account = JSON.parse(app.developer).devId;
          this.devId = isNaN(app.devId) ? JSON.parse(app.developer).devId : app.devId;
          this.genre = app.genre;
          this.screenshots = JSON.parse(app.screenshots);
          this.priceText = app.priceText;
          this.installs = app.Installs;
          this.genre = app.genre;
          this.scoreText = app.scoreText;
          this.description = app.descriptionHTML;
          this.androidVersionText = app.androidVersionText;
          this.playstoreUrl = app.playstoreUrl;
          this.appId = app.appId;
          this.updated = parseInt(app.updated);
          this.developerEmail = app.developerEmail;
          this.developerWebsite = app.developerWebsite;
          this.contentRating = app.contentRating;
          const topKeywords = this.extractKeywords(this.description + " " + this.title, 10);
          this.keywords = topKeywords;
          this.keywords.sort(() => Math.random() - 0.5);
          // this.getInstallsData();
          // this.getReviewsData();
          this.getReviewsFromApi();
          this.getDiscHtml()
        })
        .catch(console.error);
    },
    async getDiscHtml() {
      // axios
      //   .get(
      //     `https://scx.clicklab.app/api/apps/${this.appId}/?fullDetail=true&token=${this.token}`
      //   )
      const response = await connectApi(`https://scx.clicklab.app/api/apps/${this.appId}/?fullDetail=true&country=${this.country}&lang=${this.lang}`);
      try {
        const { status } = response.data;
        if (status === "Unauthorized") {
          deleteAccessTokenCookie();
          router.push({ name: "auth-login" });
          return;
        }
        const data = response.data;
        this.descriptionHTML = data.descriptionHTML;
      } catch (error) {
        console.error(error);
      }

    },
    async getReviewsFromApi() {
      // await axios
      //   .get(
      //     `https://scx.clicklab.app/api/apps/${this.appId}/?fullDetail=true&token=${this.token}`
      //   )
      try {
        const res = await connectApi(`https://scx.clicklab.app/api/apps/${this.appId}/?fullDetail=true&country=${this.country}&lang=${this.lang}`);
        const { status } = res.data;
        if (status === "Unauthorized") {
          deleteAccessTokenCookie();
          router.push({ name: "auth-login" });
          return;
        }
        const data = res.data;
        // GET ALL REVIEWS 
        var { reviews: link } = data;
        link = link.split(":")
        link[0] = "https:";
        link = link[0] + link[1]
        // await axios.get(`${link}?token=${this.token}`)
        const response = await connectApi(`${link}?token=${this.token}`);
        const reviewsList = response.data;
        this.reviewsListData = reviewsList.results.data.slice(0, 20)

        // GET ALL SIMILAR APPS
        var { similar: linkSimilar } = data;
        linkSimilar = linkSimilar.split(":")
        linkSimilar[0] = "https:";
        linkSimilar = linkSimilar[0] + linkSimilar[1]
        // await axios.get(`${linkSimilar}?token=${this.token}`)
        const response2 = await connectApi(`${linkSimilar}?token=${this.token}`);
        const similarApps = response2.data;
        this.similarListData = similarApps.results.slice(0, 9)
        // GET ALL PERMISSIONS
        var { permissions: linkPermissions } = data
        linkPermissions = linkPermissions.split(":")
        linkPermissions[0] = "https:";
        linkPermissions = linkPermissions[0] + linkPermissions[1]
        this.comeReviews = true;
      } catch (error) {
        this.niches = false;
        console.error(error);
      }
    },
    async getSelectedAppFromApi() {
      // axios
      //   .get(
      //     `https://scx.clicklab.app/api/apps/${this.$route.params.id}/?fullDetail=true&token=${this.token}`
      //   )
      const response = connectApi(`https://scx.clicklab.app/api/apps/${this.$route.params.id}/?fullDetail=true&country=${this.country}&lang=${this.lang}`);
      response
        .then((response) => {
          const { status } = response.data;
          if (status === "Unauthorized") {
            deleteAccessTokenCookie();
            router.push({ name: "auth-login" });
            return;
          }
          const data = response.data;
          return data;
        })
        .then((data) => {
          this.devId = isNaN(data.developerId) ? data.developer.devId : data.developerId;
          this.playstoreUrl = data.playstoreUrl;
          this.title = data.title;
          this.icon = data.icon;
          this.appId = data.appId;
          this.releasedDate = data.released;
          this.score = data.scoreText;
          this.summary = data.summary;
          this.version = data.version;
          this.installs = data.maxInstalls;
          this.ratings = data.ratings;
          this.account = data.developer.devId;
          this.genre = data.genre;
          this.screenshots = data.screenshots;
          this.developer = data.developer;
          this.priceText = data.priceText;
          this.installs = data.installs;
          this.genre = data.genre;
          this.scoreText = data.scoreText;
          this.description = data.description;
          this.androidVersionText = data.androidVersionText;
          this.updated = data.updated;
          const topKeywords = this.extractKeywords(this.description + " " + this.title, 10);
          this.keywords = topKeywords;
          this.keywords.sort(() => Math.random() - 0.5);
          // this.getInstallsData();
          // this.getReviewsData();
          this.getReviewsFromApi();
          this.getDiscHtml();

        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.dataFound = false;
          }

        });
    },
    extractKeywords(text, numKeywords = 10) {
      // Tokenize the text and remove punctuation
      const tokens = text.toLowerCase().match(/[\p{L}]+/gu);

      // Common stopwords in En/Ar ....
      const stopwords = [
        'and', 'the', 'is', 'this', 'in', 'for', 'of', 'on', 'with', 'to', 'as', 'at', 'an', 'or', 'com', 'out', 'a', 'just', 'more', 'https', 'you', 'up', 'it', 'get', 'your', 'can', 'en', 'be', 'join', 's', 'new', 'old', 'us', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'not', 'terms', 'privacy', 'our', 'free', 'like', 'will', 'trial', 'use', "policies", "policy", 'do', 'one', 'two', 'three', 'never', 'before', 'all', 'experience', 'from', 'through', 'many', 'if', 'take', 'features', 'that', 'even', 'his', 'help', 'authorized', 'allows', 'html', 'such', 'el', 'f', 'my', 'are', 'when', 'b','without','should','also','may',

        'I', 'l', 'et', 'la', 'le', 'de', 'ou', 'que', 'qui', 'vous', 'où', 'j', 'sur', 'à', 'je', 'plus', 'une', 'pour', 'bienvenue', 'avec', 'ce', 'un', 'ai', 'les', 'votre', 'dans', 'bien', 'qu', 'toute', 'tous', 'tout', 'est', 'd', 'notre', 'des', 'vos', 'retrouver', 'avant', 'nous', 'du', 'nos', 'application', 'téléchargez', 'va', 'retrouvez', 'visez', 'ces', 'toutes', 'c', 'depuis', 'sans', 'puis', 'par', 'cette', 'aux', 'purrez', 'y', 'nouvelle', 'infos', 'bonnes', 'sont', 'h', 'ère', 'afin', 'pourrez', 'toi', 'pas', 'ta', 'tes', 'tu', 'au', 'ma', 'avons', 'lorsque', 'faites', 'parfois',

        'los', 'te', 'tus', 'todos', 'i', 'al', 'totes', 'x', 'als', 't', 'qual', 'amb', 'las', 'todas', 'esta', 'con', 'e', 'os', 'da', 'para', 'teus', 'teu', 'mi', 'no', 'sin', 'su', 'si', 'más', 'una', 'se', 'ya', 'por', 'es', 'tienes', 'del', 'mejor', 'lotes', 'está', 'O', 'o', 'vino', 'els', 'per', 'és', 'dels', 'sobre', 'como', 'ha',

        'هو', 'و', 'في', 'أن', 'بدون', 'نت', 'عبر', 'يمكن', 'هذه', 'إذ', 'لكم', 'العديد', 'يمكنك', 'من', 'هذا', 'مع', 'بين', 'إلى', 'على', 'بكل', 'الذي', 'يقدم', 'التطبيق', 'أو', 'وكتاب', 'لابن', 'او', 'بسبب', 'أيضا', 'ا', 'ب', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'ه', 'و', 'ي', 'أ', 'ة', 'وا', 'ون', 'عن', 'بن', 'حيث', 'لهذا', 'اسمه', 'يضم', 'جميع', 'الملقب', 'ستجد', 'كل', 'ما', 'قد', 'لمن', 'يحب', 'أي', '', 'الأ', 'كما', 'اسم', 'كم', 'الاسم', 'معنى', 'وقال', 'يقال', 'يطلق', 'مدى', 'يحتوي', 'بشكل', 'وحرق', 'يتضمن', 'اد', 'ال', 'ين', 'لجميع', 'فيها', 'هي', 'جدا', 'وهي'
        // ...add more stopwords as needed
      ];

      // Remove stopwords
      const filteredTokens = tokens.filter(token => !stopwords.includes(token));

      // Calculate term frequency (TF)
      const tf = filteredTokens.reduce((acc, token) => {
        acc[token] = (acc[token] || 0) + 1;
        return acc;
      }, {});

      /// Convert TF object to an array of objects
      const keywordsArray = Object.keys(tf).map(word => ({ word, count: tf[word] }));

      // Sort keywords by count
      keywordsArray.sort((a, b) => b.count - a.count);

      // Select the top keywords
      const topKeywords = keywordsArray.slice(0, numKeywords);

      return topKeywords;
    },

    async downloadZip() {
      var formData = new FormData()
      for (let i = 0; i < this.screenshots.length; i++) {
        formData.append("urls[]", this.screenshots[i]);
      }
      formData.append("app", this.title);
      const url = this.icon;
      formData.append("iconUrl", url)
      this.screensLoadingDownload = true
      axios.post("zip-screen-creator", formData, { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/zip' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.title}.zip`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          this.showToast(
            "success",
            "SaveIcon",
            "Done",
            "App's Assets were downloaded, Enjoy!!"
          );
        })
        .then(() => {
          this.screensLoadingDownload = false
          axios.post("zip-screen-deleter").then(({ data }) => {

          }).catch((err) => {
            this.screensLoadingDownload = false
            console.error(err);
          })
        })

        .catch((err) => {
          this.screensLoadingDownload = false
          console.error(err);
        })
    },
    downloadApk() {
      window.open(`https://d.apkpure.com/b/APK/${this.appId}?version=latest`, '__blank');
    },
    goBack() {
      router.back(-1);
    }


  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 40px !important;
  height: 46px !important;
  background-color: #555;
}

.appKey {
  font-size: 1rem;
  padding-top: 12px !important;
  padding-bottom: 0 !important;
}

.appValue {
  padding-top: 0 !important;
  padding-bottom: 5px;
  font-size: 1.1rem;
  font-weight: 900 !important;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.app-summary {
  font-size: 15px;
  font-weight: 500;
  animation: myAnimation 2s;
}

@keyframes myAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}


.image-container {
  overflow-x: auto;
  display: flex;
}

.image-wrapper {
  display: flex;
}

.image-item {
  flex: 0 0 auto;
  margin-right: 10px;
  max-width: 100%;
}

.image-item img {
  width: 100%;
  height: 70vh;
  // max-width: 250px;
}

.image-container::-webkit-scrollbar {
  width: 5px;
}

.image-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; //
}

.image-container::-webkit-scrollbar-thumb {
  background-color: #050404;
  border-radius: 5px;
}

.image-container::-webkit-scrollbar-thumb:hover {
  background-color: rgb(7, 7, 60);
}

.image-container::-webkit-scrollbar-button {
  display: none;
}

.appInfoTitle {
  font-size: 1.6rem;
  font-weight: 900;
  width: fit-content;
  margin: 0 !important;
  color: #0764f9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12vh;
}

.showMoreLess {
  font-size: larger;
  font-weight: 800;
}

.asideWrapper {
  margin-block: 1rem;
}



.rowApex {
  position: relative;
  // opacity: 0.3;
}

.rowApex::before {
  content: "";
  display: block;
  padding-top: 65%;
}

.apexcharts-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
}

#infoCards {
  padding: 0 !important;
}

#infoCards .card-body {
  padding: 0;
}

.app-info-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .app-info-wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 500px) {
  .app-info-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;

  }

  #infoCards {
    margin: 3px !important;
  }

  #infoCards .card-body p {
    margin-bottom: 2px;
  }
}

.starIcon {
  color: rgb(255, 183, 0);
  fill: rgb(255, 174, 0);
  width: 20px !important;
  height: 20px !important;
}

text {
  fill: rgb(149, 149, 149) !important;
}

// text.apexcharts-xaxis-label {
//   visibility: hidden;
// }
.apexcharts-toolbar {
  display: none;
}

#appInfoLabel {
  margin: 0;
  padding: 0;
}

#appInfoLabel .card-body {
  margin: 0;
  padding: 10px 3px;
}

#appInfoMain,
#appInfoLabel {
  border-radius: 0;
}

#reviewsCard {
  height: 60vh;
  overflow-y: scroll;
  margin-top: 20px;

}

#reviewsCard::-webkit-scrollbar {
  width: 8px;
}

#reviewsCard::-webkit-scrollbar-track {
  background-color: transparent; //
}

#reviewsCard::-webkit-scrollbar-thumb {
  background-color: #050404;
  border-radius: 5px;
}

#reviewsCard::-webkit-scrollbar-thumb:hover {
  background-color: rgb(7, 7, 60);
}

#reviewsCard::-webkit-scrollbar-button {
  display: none;
}

.review-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.review-text {
  flex: 1;
  margin-right: 10px;
}

.review-stars {
  display: flex;
  align-items: center;
}

.review-stars p {
  margin-bottom: 5px;
}

.review-date {
  color: #888;
}

.table {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 5px;
  width: 50%;
}

@media screen and (max-width: 500px) {
  .buttonSm {
    display: block;
    width: 100%;
  }

  .buttonMd {
    display: none;
  }
}

@media screen and (min-width: 500px) {
  .buttonSm {
    display: none;
  }

  .buttonMd {
    display: block;
  }
}

.buttonSm button,
.buttonMd button {
  background-color: transparent !important;
}

.rtl {
  direction: rtl;
  text-align: justify;
}

.similarAppIcon {
  width: 100%;
  border-radius: 50%;
}

ul.cloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center !important;
  line-height: 2.75rem;
  width: 450px;
  max-width: 100%;
}

ul.cloud a {
  /*   
    Not supported by any browser at the moment :(
    --size: attr(data-weight number); 
    */
  --size: 4;
  --color: #fff;
  font-size: calc(var(--size) * 0.25rem + 0.5rem);
  display: block;
  padding: 0.125rem 0.25rem;
  position: relative;
  text-decoration: none;
  /* 
    For different tones of a single color
    opacity: calc((15 - (9 - var(--size))) / 15); 
    */
}

ul.cloud a[data-weight="1"] {
  --size: 1;
}

ul.cloud a[data-weight="2"] {
  --size: 2;
}

ul.cloud a[data-weight="3"] {
  --size: 3;
}

ul.cloud a[data-weight="4"] {
  --size: 4;
}

ul.cloud a[data-weight="5"] {
  --size: 5;
}

ul.cloud a[data-weight="6"] {
  --size: 6;
}

ul.cloud a[data-weight="7"] {
  --size: 7;
}

ul.cloud a[data-weight="8"] {
  --size: 8;
}

ul.cloud a[data-weight="9"] {
  --size: 9;
}

ul.cloud a[data-weight="10"] {
  --size: 10;
}

ul.cloud a[data-weight="11"] {
  --size: 11;
}

ul.cloud a[data-weight="12"] {
  --size: 12;
}

ul.cloud a[data-weight="13"] {
  --size: 13;
}

ul.cloud a[data-weight="14"] {
  --size: 14;
}

ul.cloud a[data-weight="15"] {
  --size: 15;
}

ul.cloud a[data-weight="16"] {
  --size: 16;
}

ul.cloud a[data-weight="17"] {
  --size: 17;
}

ul.cloud a[data-weight="18"] {
  --size: 18;
}

ul.cloud a[data-weight="19"] {
  --size: 19;
}

ul[data-show-value] a::after {
  content: " (" attr(data-weight) ")";
  font-size: 1rem;
}

ul.cloud li:nth-child(2n+1) a {
  --color: #fff;
}

ul.cloud li:nth-child(3n+1) a {
  --color: #fff;
}

ul.cloud li:nth-child(4n+1) a {
  --color: #fff;
}

ul.cloud a:focus {
  outline: 1px dashed;
}

ul.cloud a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background: var(--color);
  transform: translate(-50%, 0);
  opacity: 0.15;
  transition: width 0.25s;
}

ul.cloud a:focus::before,
ul.cloud a:hover::before {
  width: 100%;
}

@media (prefers-reduced-motion) {
  ul.cloud * {
    transition: none !important;
  }
}

.loader-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  margin-top: 12rem;
  /* Add blur effect to the background */
}

/* Center the spinner */
.loader-overlay .spinner-grow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>